import { t } from 'i18next';
import { v4 as uuid } from 'uuid';
import {
  chatWorkspaceIcon,
  conferencesWorkspaceIcon,
  contactsWorkspaceIcon,
  faxesWorkspaceIcon,
  phoneWorkspaceIcon
} from '../../../assets/workspaces';
import { KazooSDK } from '@commland/kazoo-js-sdk';
import { getIsChatWorkspaceEnabled } from '../utils';
import { KazooApplication } from '../types';

const buildWorkspaceBaseDocument = (
  name: string
): Partial<KazooApplication> => {
  const id = uuid();

  return {
    id,
    uuid: id,
    name,
    version: '2.7.0',
    i18n: {
      'en-US': {
        label: t(`routing.workspaces.${name}`),
        features: [],
        extended_description: name,
        description: name
      }
    }
  };
};

const buildApplicationBaseDocument = (
  name: string
): Partial<KazooApplication> & { hidden: true } => {
  const id = uuid();

  return {
    id,
    uuid: id,
    name,
    version: '2.7.0',
    hidden: true,
    i18n: {
      'en-US': {
        label: name,
        features: [],
        extended_description: name,
        description: name
      }
    }
  };
};

const fetchApplications = () => {
  return [
    buildApplicationBaseDocument('account-settings'),
    buildApplicationBaseDocument('settings')
  ];
};

const fetchUIShell = () => {
  return [
    buildApplicationBaseDocument('toolbar'),
    buildApplicationBaseDocument('navbar')
  ];
};

/**
 *  the version property is meant for the dock to know
 *  when a workspace document has been updated and if/when the url
 *  has changed, i.e. /userportal/conferences to /conferences-workspace
 */
const fetchWorkspaces = async () => {
  if (
    !KazooSDK.credentials ||
    !KazooSDK.credentials.account_name ||
    !KazooSDK.credentials.credentials
  ) {
    return [];
  }

  const isChatWorkspaceEnabled = await getIsChatWorkspaceEnabled();

  return [
    ...(isChatWorkspaceEnabled
      ? [
          {
            ...buildWorkspaceBaseDocument('chat-workspace'),
            icon: chatWorkspaceIcon,
            phase: 'beta'
          }
        ]
      : []),
    {
      ...buildWorkspaceBaseDocument('conferences-workspace'),
      icon: conferencesWorkspaceIcon,
      phase: 'beta'
    },
    {
      ...buildWorkspaceBaseDocument('contacts-workspace'),
      icon: contactsWorkspaceIcon
    },
    {
      ...buildWorkspaceBaseDocument('faxes-workspace'),
      icon: faxesWorkspaceIcon
    },
    {
      ...buildWorkspaceBaseDocument('phone-workspace'),
      icon: phoneWorkspaceIcon
    },
    {
      id: uuid(),
      uuid: uuid(),
      name: 'screenpops',
      version: '2.4.0',
      hidden: true
    },
    {
      ...buildWorkspaceBaseDocument('external-websites'),
      url: '/websites',
      hidden: true
    }
  ];
};

const fetchDefaultApplications = async () => {
  const uiShell = fetchUIShell();
  const applications = fetchApplications();
  const workspaces = await fetchWorkspaces();

  return [...uiShell, ...workspaces, ...applications];
};

export default fetchDefaultApplications;
