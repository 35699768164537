import logger from '../utils/logger';

type Theme = 'Light' | 'Dark';
type ThemeColor = 'Default' | string;

class Themes {
  init() {
    logger.info('themes: Initializing themes module');
    const whitelabel = window.commland?.branding?.whitelabel?.commland || null;
    const whitelabelExists = whitelabel && Object.keys(whitelabel).length > 0;

    //  SDS 2.0
    const theme = localStorage.getItem('commland-theme');
    const colorTheme = localStorage.getItem('commland-themecolor');

    const themeToApply = !theme || theme === null ? 'Light' : theme;
    this.applyTheme(themeToApply as Theme);

    let colorThemeToApply = 'Default';
    if (!colorTheme || colorTheme === null || whitelabelExists) {
      colorThemeToApply = whitelabel?.theme?.primary_color;
    }
    this.applyColorTheme(colorThemeToApply);

    // SDS 3.0
    const colorMode = localStorage.getItem('commland-sds-colormode');
    const themeColor = localStorage.getItem('commland-sds-theme');

    const colorModeToApply =
      !colorMode || colorMode === null ? 'Light' : colorMode;
    this.applyThemeColorMode(colorModeToApply as Theme);

    let themeColorToApply = 'Default';
    if (!themeColor || themeColor === null || whitelabelExists) {
      themeColorToApply = whitelabel?.theme?.primary_color;
    }
    this.applyThemeColor(themeColorToApply);
  }

  // SDS 2.0 - Deprecate this when we move everything to SDS 3.0
  applyTheme(theme: Theme = 'Light') {
    logger.info('themes: applying theme:', theme);
    document.body.setAttribute('data-theme', theme);
    localStorage.setItem('commland-theme', theme);
    if (window.commlandEvents) {
      window.commlandEvents.emit('commland.user.preferences.theme', theme);
    }
  }

  applyColorTheme(theme: ThemeColor = 'Default') {
    logger.info('themes: applying color theme:', theme);
    document.body.setAttribute('data-themecolor', theme);
    localStorage.setItem('commland-themecolor', theme);
    if (window.commlandEvents) {
      window.commlandEvents.emit(
        'commland.account.preferences.theme.color',
        theme
      );
    }
  }

  // SDS 3.0 - Creating separate functions for projects that still doesn't have sds 3
  applyThemeColorMode(theme: Theme = 'Light') {
    logger.info('themes: applying sds-colormode:', theme);
    document.body.setAttribute('data-sds-colormode', theme);
    localStorage.setItem('commland-sds-colormode', theme);
    if (window.commlandEvents) {
      window.commlandEvents.emit('commland.user.preferences.color.mode', theme);
    }
  }

  applyThemeColor(color: ThemeColor = 'Default') {
    logger.info('themes: applying sds-theme:', color);
    document.body.setAttribute('data-sds-theme', color);
    localStorage.setItem('commland-sds-theme', color);
    if (window.commlandEvents) {
      window.commlandEvents.emit(
        'commland.account.preferences.theme.color',
        color
      );
    }
  }
}

export default Themes;
