import { KazooSDK } from '@commland/kazoo-js-sdk';

export const isApplicationUIShell = (application: string) => {
  const uiShell = ['navbar', 'toolbar'];
  return uiShell.includes(application);
};

export const isApplicationWorkspace = (application: string) => {
  if (application === 'external-websites' || application === 'screenpops') {
    return true;
  }

  return application.endsWith('-workspace');
};

export const getIconURL = (appId: string) => {
  const accountId = KazooSDK.getAccountId();
  const authToken = KazooSDK.getAuthToken();
  const apiUrl = window.config.API_URL;
  const userId = KazooSDK.getUserId();

  return `${apiUrl}/accounts/${accountId}/users/${userId}/applications/commland_ui/${appId}/icon?auth_token=${authToken}`;
};
