import type { KazooApplication } from '../types';
import { KazooSDK } from '@commland/kazoo-js-sdk';
import logger from '../../../utils/logger';
import { v4 as uuid } from 'uuid';
import { getIconURL } from './utils';
import fetchDefaultApplications from './defaults';

const ALPHA_APPLICATIONS = ['webhooks', 'branding'];

const BETA_APPLICATIONS = [
  'conferences-admin',
  'screenpops-admin',
  'pivot',
  'ms-teams'
];

export const fetchUserApplications = async (): Promise<KazooApplication[]> => {
  const accountId = KazooSDK.getAccountId();
  const userId = KazooSDK.getUserId();
  const isUserAuthenticated = window.commland.auth.isUserAuthenticated();

  if (!isUserAuthenticated) {
    logger.info('User is not authenticated, returning login');

    return [
      {
        id: uuid(),
        uuid: uuid(),
        name: 'login',
        version: '-',
        source_url: 'http://127.0.0.1:4040/ui/login',
        i18n: {
          'en-US': {
            label: 'Login',
            features: [],
            extended_description: 'login',
            description: 'login'
          }
        },
        allowed_users: 'all',
        screenshots: [],
        published: true,
        price: 0,
        phase: 'gold',
        license: '',
        icon: '',
        icon_url: '',
        authority: '',
        author: '',
        api_url: '',
        masqueradable: false,
        account_id: false
      }
    ];
  }

  try {
    const {
      data: { data: applications }
    } = await KazooSDK.get(
      `/accounts/${accountId}/users/${userId}/applications/commland_ui`
    );

    const defaultApplications = await fetchDefaultApplications();

    const cm = JSON.parse(`
      {
        "version": "1.0",
        "uuid": "9877afba-95c3-4445-a7b0-654034f95ddd",
        "urls": {
          "howto": "{howto_video_url}",
          "documentation": "{documentation_url}"
        },
        "type": "commland_ui",
        "tags": [
          "end_user"
        ],
        "screenshots": [],
        "published": true,
        "price": 0,
        "phase": "gold",
        "name": "cluster-manager",
        "masqueradable": true,
        "license": "-",
        "icon": "cluster-manager.png",
        "i18n": {
          "en-US": {
            "label": "Cluster Manager",
            "features": [
              "Call queues"
            ],
            "extended_description": "CM",
            "description": "CM"
          }
        },
        "author": "2600Hz",
        "id": "9877afba-95c3-4445-a7b0-654034f95ddd"
      }
    `);


const provisionerTemplate = {
      uuid: 'a6264b85-0b30-424f-8025-24016bcf1578',
      id: 'a6264b85-0b30-424f-8025-24016bcf1578',
      name: 'provisioner-template-editor',
      i18n: {
        'en-US': {
          label: 'Provisioner-Template-Editor',
          description: 'Create provisioner template for devices.',
          extended_description:
            'commland frontend for provisioner backend which is an API based middleware project which is given work via github webhooks. Responsible for marshaling build artifacts and depositing them into a package repo, handling releases, the relationships between metapackage releases and their dependencies, producing change logs from the changes found in github. ',
          features: [
            'Create provisioner template for devices',
            'Upload firmwares',
            'Create Parameters',
            'Image & Hotspot Management',
            'Parameters Association'
          ]
        }
      },
      tags: ['system_admin'],
      icon: 'provisioner.png',
      author: '2600Hz',
      version: '1.0',
      license: '-',
      price: 0,
      screenshots: [],
      urls: {
        documentation: '{documentation_url}',
        howto: '{howto_video_url}'
      },
      phase: 'gold',
      pvt_type: 'app'
    };
    const provisioner = {
      uuid: '148e2d7b-7a18-4a3d-a6c9-8d29c4c4263e',
      name: 'provisioner',
      i18n: {
        'en-US': {
          label: 'Provisioner',
          description:
            'Create builds, upgrade and create release notes for releases.',
          extended_description:
            'commland frontend for shipyard backend which is an API based middleware project which is given work via github webhooks. Responsible for marshaling build artifacts and depositing them into a package repo, handling releases, the relationships between metapackage releases and their dependencies, producing change logs from the changes found in github. ',
          features: [
            'Create builds for metapackages and their subpackages, you can change the value of version to be built',
            'Upgrade packages to stable',
            'Send approval requests before upgrading to make sure everyone is on board with upgrading a package',
            'Add deployment notes to upgrades to inform operations of any required',
            'See the list of actions performed by shipyard, who triggered them and what package was affected',
            'Create release notes for releases by selecting only relevant changes since the last stable release'
          ]
        }
      },
      tags: ['system_admin'],
      icon: 'Provisioner_app.png',
      author: '2600Hz',
      version: '1.0',
      license: '-',
      price: 0,
      screenshots: [],
      urls: {},
      phase: 'gold',
      type: 'commland_ui',
      masqueradable: true,
      published: true,
      id: '148e2d7b-7a18-4a3d-a6c9-8d29c4c4263e'
    };
    const apps = [
      ...defaultApplications,
      ...applications,
      provisioner,
      provisionerTemplate,
      cm
    ];



    // @ts-expect-error: Temporary
    return apps.map((application: KazooApplication) => {
      let phase = 'gold';

      // Workaround while we find a way to automate in which state an application is using the api - DUPT-441
      // The api does support the phase tag, but we still don't know who or how the apps are gonna be installed for commland_ui app type
      const isBetaApp = BETA_APPLICATIONS.includes(application.name);
      const isAlphaApp = ALPHA_APPLICATIONS.includes(application.name);
      if (isBetaApp) {
        phase = 'beta';
      } else if (isAlphaApp) {
        phase = 'alpha';
      }

      return {
        ...application,
        icon_url: getIconURL(application.id),
        phase
      };
    });
  } catch (error) {
    logger.error('There was an error retrieving comm.land apps', error);
    return [];
  }
};
